<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
    >
      <v-list dense>
        <v-list-item link to="/admin">
          <v-list-item-action>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>  

        <v-list-item link to="/admin/company-info">
          <v-list-item-action>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Company Info</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/admin/pages">
          <v-list-item-action>
            <v-icon>mdi-book-open-page-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Pages</v-list-item-title>
          </v-list-item-content>
        </v-list-item>   

        <v-list-item link to="/admin/services">
          <v-list-item-action>
            <v-icon>mdi-briefcase</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Services</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/admin/portfolio">
          <v-list-item-action>
            <v-icon>mdi-smart-card-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Portfolio</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/admin/technologies">
          <v-list-item-action>
            <v-icon>mdi-laptop</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Technologies</v-list-item-title>
          </v-list-item-content>
        </v-list-item>     

        <v-list-group        
        prepend-icon="mdi-cog"
        no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link to="/admin/settings/email-services"> 
              <v-list-item-action>
                <v-icon>mdi-message-cog</v-icon>
              </v-list-item-action>           
              <v-list-item-content>
                <v-list-item-title>Email Services</v-list-item-title>
              </v-list-item-content>
          </v-list-item>   

          <v-list-item link to="/admin/settings/analytics"> 
              <v-list-item-action>
                <v-icon>mdi-chart-timeline-variant</v-icon>
              </v-list-item-action>           
              <v-list-item-content>
                <v-list-item-title>Analytics</v-list-item-title>
              </v-list-item-content>
          </v-list-item>        
        </v-list-group>

        <v-list-group        
        prepend-icon="mdi-account-cog"
        no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>User Management</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link to="/admin/users"> 
              <v-list-item-action>
                <v-icon>mdi-account</v-icon>
              </v-list-item-action>           
              <v-list-item-content>
                <v-list-item-title>Users</v-list-item-title>
              </v-list-item-content>
          </v-list-item>

          <v-list-item link to="/admin/roles"> 
              <v-list-item-action>
                <v-icon>mdi-account-clock</v-icon>
              </v-list-item-action>           
              <v-list-item-content>
                <v-list-item-title>Roles</v-list-item-title>
              </v-list-item-content>
          </v-list-item>

          <v-list-item link to="/admin/permissions"> 
              <v-list-item-action>
                <v-icon>mdi-account-cancel</v-icon>
              </v-list-item-action>           
              <v-list-item-content>
                <v-list-item-title>Permissions</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group        
        prepend-icon="mdi-account-voice"
        no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Marketing</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-group
            no-action
            sub-group
            value="true"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Audience</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item link to="/admin/audience/all"> 
                <v-list-item-content>
                  <v-list-item-title>Contacts</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>mdi-account-box-outline</v-icon>
                </v-list-item-action>               
            </v-list-item>

            <v-list-item link to="/admin/audience/tags">
                <v-list-item-content>
                  <v-list-item-title>Tags</v-list-item-title>
                </v-list-item-content> 
                <v-list-item-action>
                  <v-icon>mdi-tag</v-icon>
                </v-list-item-action>               
            </v-list-item> 

            <v-list-item link to="/admin/audience/segments">
                <v-list-item-content>
                  <v-list-item-title>Segments</v-list-item-title>
                </v-list-item-content> 
                <v-list-item-action>
                  <v-icon>mdi-chart-pie</v-icon>
                </v-list-item-action>               
            </v-list-item>           
          </v-list-group>

          <v-list-group
            no-action
            sub-group
            value="true"
          >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Campaigns</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item link to="/admin/campaign">
                  <v-list-item-content>
                    <v-list-item-title>Management</v-list-item-title>
                  </v-list-item-content> 
                  <v-list-item-action>
                    <v-icon>mdi-cast-audio</v-icon>
                  </v-list-item-action>                 
              </v-list-item>

              <v-list-item link to="/admin/email-templates"> 
                  <v-list-item-content>
                    <v-list-item-title>Email Templates</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-email</v-icon>
                  </v-list-item-action>                 
              </v-list-item>              
            </v-list-group>

          
        </v-list-group>

        <v-list-item link to="/admin/medialibrary">
            <v-list-item-action >
              <v-icon>mdi-folder-multiple-image</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Media Library</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item @click="sitemap">
          <v-list-item-action>
            <v-icon>mdi-sitemap</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Generate Sitemap</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logout">
          <v-list-item-action>
            <v-icon>mdi-power</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        


      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-left
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>King Of Web Design</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container
        class="fill-height"
        fluid
        style="align-items: start !important;"
      >
        <v-row
          
          justify="center"
        >
          
                <router-view @openError="openErrorDialog" @addErrorMessage="errorMessage" @openResponse="openResponseDialog" @addResponseMessage="responseMessage" :user="user"></router-view>
                <ErrorDialog  :errors="errors" :errorDialog="errorDialog" v-on:close-dialog="closeDialog"></ErrorDialog>
                <ResponseDialog  :response="response" :responseDialog="responseDialog" v-on:close-dialog="closeDialog"></ResponseDialog>
              
        </v-row>
      </v-container>
    </v-main>

    <v-footer app>
      <span>Matthew King Freelance &copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>

<script>
  export default {
    props: {
      source: String,
      user: Object,
    },

    data: () => ({
      drawer: null,
      drawer: null,
      errorDialog: false, 
      responseDialog: false,
      newErrors: [],
      errors: [],   
      response: '',
    }),

    created () {
      this.$vuetify.theme.dark = true
    },

    methods: {
        openErrorDialog(value) {
          this.errorDialog = value;          
        },

        errorMessage(value) {
          this.errors = value;          
        },

        openResponseDialog(value) {
          this.responseDialog = value;          
        },

        responseMessage(value) {
          this.response = value;          
        },

        closeDialog: function(){            
            this.errorDialog = false;
            this.responseDialog = false;
        },

        logout() {
                axios.post('/logout').then(response => {
                    this.window.location.href = "login"

                }).catch(error => {
                    location.reload();
                });
        },

        sitemap () {    
           

            axios.post('/api/sitemap/generate')
              .catch(error => 
              {
                if(error.response.status == 422)
                  {
                    this.newErrors = error.response.data.errors;
                    this.newErrors = Object.values(this.newErrors);

                    for( var i = 0; i < this.newErrors.length; i++ ){                  
                      this.errors[i] = this.newErrors[i][0].replace('title.'+i, i+1);
                    }
                    this.errorDialog = true;

                    this.$emit("openError", this.errorDialog)
                    this.$emit("addErrorMessage", this.errors)
                  }
              })
            .then((response) => 
              {
                    this.response = response.data;
                    this.responseDialog = true;

                    this.$emit("openResponse", this.responseDialog)
                    this.$emit("addResponseMessage", this.response)

                    setTimeout(() => this.$router.push("/admin/technologies"), 1501);
              }); 
          
          },     
    },
  }
</script>

