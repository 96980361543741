/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */



window.Vue = require('vue');

import VueRouter from 'vue-router'
import Vue from 'vue'
import vuetify from 'laravel-mix/src/webpackPlugins/vuetify' // path to vuetify export
import VueMeta from 'vue-meta';
import axios from 'axios';

window.axios = axios;

Vue.use(VueRouter)
Vue.use(require('vue-moment'));
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('admin', require('./components/Admin.vue').default);
Vue.component('ErrorDialog', () => import('./components/ErrorDialog.vue'));
Vue.component('ResponseDialog', () => import('./components/ResponseDialog.vue'));
Vue.component('Gutenberg', () => import('./components/Gutenberg.vue'));
Vue.component('index', () => import('./components/Index.vue'));

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.


  */

 let Dashboard = () => import ('./AdminPages/Dashboard');
 let Users = () => import ('./AdminPages/Users');
 let Roles = () => import ('./AdminPages/Roles');
 let Permissions = () => import ('./AdminPages/Permissions');
 let EmailServices = () => import ('./AdminPages/EmailServices');
 let Analytics = () => import ('./AdminPages/Analytics');
 let MediaLibrary = () => import ('./pages/MediaLibrary');
 let CompanyInfo = () => import ('./AdminPages/ServiceCompanyPages/CompanyInfo');

 /** Pages Creation */
 let PagesSummary = () => import ('./AdminPages/ServiceCompanyPages/PagesCreator/PagesSummary');
 let PageSEOSummary = () => import ('./AdminPages/ServiceCompanyPages/PagesCreator/PageSEOSummary');
 let PageImages = () => import ('./AdminPages/ServiceCompanyPages/PagesCreator/PageImages');
 let PagesEdit = () => import ('./AdminPages/ServiceCompanyPages/PagesCreator/PagesEdit');
 /** End Of Pages Creation */

 /** Services Creation */
 let ServicesSummary = () => import ('./AdminPages/ServiceCompanyPages/ServicesCreator/ServicesSummary');
 let ServicesSEOSummary = () => import ('./AdminPages/ServiceCompanyPages/ServicesCreator/ServicesSEOSummary');
 let ServicesImages = () => import ('./AdminPages/ServiceCompanyPages/ServicesCreator/ServicesImages');
 let ServicesEdit = () => import ('./AdminPages/ServiceCompanyPages/ServicesCreator/ServicesEdit');
 /** End Of Services Creation */

  /** Services Creation */
 let PortfolioSummary = () => import ('./AdminPages/ServiceCompanyPages/PortfolioCreator/PortfolioSummary');
 let PortfolioSEOSummary = () => import ('./AdminPages/ServiceCompanyPages/PortfolioCreator/PortfolioSEOSummary');
 let PortfolioImages = () => import ('./AdminPages/ServiceCompanyPages/PortfolioCreator/PortfolioImages');
 let PortfolioEdit = () => import ('./AdminPages/ServiceCompanyPages/PortfolioCreator/PortfolioEdit');
 /** End Of Services Creation */

/** Technology Creation */
 let TechnologySummary = () => import ('./AdminPages/ServiceCompanyPages/TechnologiesCreator/TechnologySummary'); 
 let TechnologyImages = () => import ('./AdminPages/ServiceCompanyPages/TechnologiesCreator/TechnologyImages');
 let TechnologyEdit = () => import ('./AdminPages/ServiceCompanyPages/TechnologiesCreator/TechnologyEdit');
 /** End Of Technology Creation */

 /** Marketing Modules */
 let Audience = () => import ('./AdminPages/Marketing/Audience');
 let Tags = () => import ('./AdminPages/Marketing/Tags');
 let Campaign = () => import ('./AdminPages/Marketing/Campaign');
 let EmailTemplates = () => import ('./AdminPages/Marketing/EmailTemplates');
 let AddEmailTemplates = () => import ('./AdminPages/Marketing/AddEmailTemplates');
 let ViewAllAudience = () => import ('./AdminPages/Marketing/ViewAllAudience');
 let AddAudience = () => import ('./AdminPages/Marketing/AddAudience');
 let ViewAudience = () => import ('./AdminPages/Marketing/ViewAudience');
 let Segments = () => import ('./AdminPages/Marketing/Segments');
 let AddSegment = () => import ('./AdminPages/Marketing/AddSegment');

 /** Front-end */
 let Home = () => import ('./pages/HomeContent');
 let ProjectSummary = () => import ('./pages/ProjectContent');
 let ContactContent = () => import ('./pages/ContactContent');
 let PageContent = () => import ('./pages/PageContent');

/** End of Front-end */

 const routes = [
{
		path: '/',
		component: Home
},

{
		path: '/our-projects',
		component: ProjectSummary
},

{
		path: '/contact',
		component: ContactContent
},

{
		path: '/admin/',
		component: Dashboard
},

{
		path: '/admin/users',
		component: Users
},

{
		path: '/admin/roles',
		component: Roles
},

{
		path: '/admin/settings/email-services',
		component: EmailServices
},

{
		path: '/admin/settings/analytics',
		component: Analytics
},

{
		path: '/admin/permissions',
		component: Permissions
},

{
		path: '/admin/company-info',
		component: CompanyInfo
},

/** Pages Routes */

{
		path: '/admin/pages',
		component: PagesSummary
},

{
		path: '/admin/pages/:slug',
		component: PagesEdit
},

{
		path: '/admin/pages/:slug/seo',
		component: PageSEOSummary
},

{
		path: '/admin/pages/:slug/images',
		component: PageImages		
},

{
		path: '/admin/pages/:slug/:id',
		component: PagesEdit
},

/** Page Routes End */

/** Marketing Modules */
{
		path: '/admin/audience',
		component: Audience,
		children: [
	        {	          
	          path: 'all',
	          component: ViewAllAudience
	        },
	        {	          
	          path: 'add',
	          component: AddAudience
	        },
	        {	          
	          path: 'tags',
	          component: Tags
	        },
	        {	          
	          path: 'segments',
	          component: Segments
	        },	        

	      ]
},
{	          
        path: '/admin/audience/view/:id',
        component: ViewAudience
},

{	          
      	path: '/admin/audience/segments/:slug',
      	component: AddSegment
},
{	          
		path: '/admin/audience/segments/:slug/:id',
		component: AddSegment
},

{
		path: '/admin/campaign',
		component: Campaign
},

{
		path: '/admin/medialibrary',
		component: MediaLibrary
},

{
		path: '/admin/email-templates',
		component: EmailTemplates
},

{
		path: '/admin/email-templates/add',
		component: AddEmailTemplates
},

/** Services Routes */

{
		path: '/admin/services',
		component: ServicesSummary
},

{
		path: '/admin/services/:slug',
		component: ServicesEdit
},

{
		path: '/admin/services/:slug/seo',
		component: ServicesSEOSummary
},

{
		path: '/admin/services/:slug/images',
		component: ServicesImages		
},

{
		path: '/admin/services/:slug/:id',
		component: ServicesEdit
},

/** Services Routes End */

/** Portfolio Routes */

{
		path: '/admin/portfolio',
		component: PortfolioSummary
},

{
		path: '/admin/portfolio/:slug',
		component: PortfolioEdit
},

{
		path: '/admin/portfolio/:slug/seo',
		component: PortfolioSEOSummary
},

{
		path: '/admin/portfolio/:slug/images',
		component: PortfolioImages		
},

{
		path: '/admin/portfolio/:slug/:id',
		component: PortfolioEdit
},

/** Portfolio Routes End */

/** Technology Routes */

{
		path: '/admin/technologies',
		component: TechnologySummary
},

{
		path: '/admin/technologies/:slug',
		component: TechnologyEdit
},

{
		path: '/admin/technologies/:id/images',
		component: TechnologyImages		
},

{
		path: '/admin/technologies/:slug/:id',
		component: TechnologyEdit
},

/** Technology Routes End */

{
		path: '/:slug',
		component: PageContent
},

];


var router = new VueRouter({

  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      return {
	      selector: to.hash	      
      }
    } else {
      return {x: 0, y: 0}
    }
  },
  routes: routes,
  mode: 'history'
});

const app = new Vue({
  el: '#app',
  router: router,
  vuetify, 
});
